<template>
    <div class="main">
        <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file" :multiple="multiplefiles" name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
            :accept="accept" />
            <label for="fileInput" class="file-label d-flex align-center justify-center">
                <div v-if="isDragging">Release to drop files here.</div>
                <div v-else-if="fileName" class="file-label" >
                  {{ fileName.length > 0 ? fileName.map(file => file.name).join(', ') : 'file added' }}
                </div>
                <div class="text-body-2 d-flex" v-else>
                    Drag a file here (or) <p class="text-decoration-underline blue--text ma-0 pa-0 px-2">upload a file</p> 
                </div>
            </label>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      isDragging: false,
      fileName:''
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingpercent: {
      type: Number,
      default: 0,
    },
    files:{
      type: Array,
      default: () => []
    },
    accept:{
      type:String,
      default: '.xlsx,.xls,.xlsm,.xlsb,.doc,.docx,.ppt,.pptx,.pdf,.png,.jpg,.jpeg,.svg'
    },
    multiplefiles:{
      type:Boolean,
      default:true
    },
    resetTrigger: {  // New prop to trigger reset
      type: Boolean,
      default: false,
    },
  },
  mounted(){
    this.fileName="";
  },
  methods: {
    dragover(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    dragleave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    drop(event) {
      event.preventDefault();
      this.isDragging = false;
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    onChange(event) {
      const files = event.target.files;
      this.handleFiles(files);
    },
    handleFiles(files) {
      this.files = Array.from(files);
      this.fileName = this.files
      console.log(this.fileName,files,"teefsdfw");
      this.$emit("update:files", this.files); 
    },
    resetFiles() {
      this.fileName = '';
      this.$refs.file.value = '';  
      this.$emit('update:files', []);  
    },
  },
  watch:{
    resetTrigger(newVal) {
      console.log("reset",newVal);
      if (newVal) {
        this.resetFiles(); 
      }
    },
  }
};
</script>



<style lang="scss" scoped>
.main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.dropzone-container {
    padding: 4rem;
    background: white;
    border: 1px dashed #ddd;
    width: 100%;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 15px !important;
    display: block;
    cursor: pointer;
}

</style>