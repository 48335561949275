<template>
  <div v-if="this.$vuetify.breakpoint.smAndUp">
    <div :class="`fullloadingscreen ${(this.loading===0)?'fade-out-image':''}`">
      <div class="loadingbg-1"></div>
      <div class="loadingbg-2"></div>
      <div class="loadingbg-3"></div>
      <div class="loadingbg-4"></div>
      <div class="text-center fade-in-image">
        <div class="">
          <img src="/img/consark/logo.png" class="logo-image" alt="logo-image"/>
        </div>
        <img src="/img/loader_new.svg" />
      </div>
    </div>
    <router-view v-if="pass || $store.state.login"></router-view>
    <Login v-else />
  </div>
  <div v-else>
    <div class="smallsize" style="height:100vh">
      <img src="/img/consark/logo.png" class="logo-image" alt="logo-image"/>
      <span class="pt-4">This application doesn't work on mobile screen size.<br/>Please use a tablet or laptop to proceed</span>
      <a href="consark.ai" class="link">Goto Website</a>
    </div>
  </div>
</template>

<script>
import Login from './views/Auth/Login.vue';

export default {
  components: { Login },
  name: 'App',

  data: () => ({
    pass: false,
    loading: 1,
    //
  }),
  created: function(){
    this.reloadData();
  },
  methods: {
    reloadData: function(){
      let oldpass = this.pass;
      if(window.location.href.substring(100,0).indexOf("/pbc/requestee/") > -1) oldpass = true;
      else if(window.location.href.substring(100,0).indexOf("/setpassword") > -1) oldpass = true;
      else if(window.location.href.substring(100,0).indexOf("/setnewpassword") > -1) oldpass = true;
      else if(window.location.href.substring(100,0).indexOf("/forgotpassword") > -1) oldpass = true;
      else if(window.location.href.substring(100,0).indexOf("/selfservice") > -1) oldpass = true;
      else oldpass = false;
      if(oldpass !== this.pass) {
        this.pass = oldpass;
        if(this.pass){
          this.$store.commit("changeNoAuth", true);
          this.$store.commit("changeLogin", true);
        }else{
          this.$store.commit("changeNoAuth", false);
          this.$store.commit("changeLogin", false);
        }
      }
    },
  },
  watch: {
    "$store.state.loading": function(d){
      this.loading = d;
    },
    $route(to, from) {
      if(from.query.cid !== to.query.cid) {
        this.loading = 1;
        this.$router.go();
      }else if(from.query.cid === undefined) this.reloadData();
    },
  }
};
</script>

<style lang="scss">

  html{
    overflow: hidden;
  }
  #main{
    max-height: 100vh;
    overflow: auto;
    background-color: white;
  }
  .v-application{
    background-image: linear-gradient(to top right, #dae5ed, #fafafa) !important;
  }
  .logo-image{
    width: 220px !important;
  }
  body *, .v-application{
    font-family:  "Figtree", sans-serif !important;
    & .subtitle-1, .subtitle-2, .title{
      font-family:  "Figtree", sans-serif !important;
    }

  }
  #app{
    background-color: transparent !important;
    overflow: hidden;
  }
  .shadow-off{
    box-shadow: 0 0 0 0 rgba(0,0,0,0)!important;
  }
  .radius-all{
   border-radius: 50%;
  }
  .radius-off{
    border-radius: 0px !important;
  }
  .radius-right{
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
  .smallsize{
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    align-items: center;
    justify-content: center;
    text-align: center;
    & .link{
      text-decoration: none;
      padding: 5px 20px;
      border-radius: 15px;
      margin-top: 20px;
      color: #333;
      border: 1px solid #333
    }
  }
  .smallsize img{
    margin-bottom: 10px;
  }
  .vertical-setup .tabs{
    min-width: 200px;
    max-height: 83vh;
    margin-right: 12px;
  }
  .vertical-setup .tabs .head{
    padding: 25px 0px 3px;
    margin: 0 15px 0 0;
    font-weight: bold;
    color: rgba(25, 52, 65, 0.6);
  }
  .vertical-setup .tabs .link{
    cursor: pointer;
    display: block;
    width: 100%;
    text-transform: initial;
    justify-content: left !important;
    padding: 5px 15px;
    margin: 0 15px 0 0;
  }
  .vertical-setup .tabs .selected{
    background-color: #fff !important;
  }
  .vertical-setup .content-window{
    position: relative;
    flex-grow: 1;
    width: 100%;
  }
  .vertical-setup .content-window .content-box{
    position: absolute;
    width: 100%;
  }
  .position-sticky{
    position: sticky;
    top: 0px;
    z-index: 3;
  }
  .position-sticky-left{
    position: sticky !important;
    left: 0px !important;
    z-index: 3;
  }

  .highlight {
    background-color: yellow;
  }

  // tooltip css
  .v-tooltip__content{
    padding: 2px 8px !important;
    background-color: rgba(0,0,0,0.9) !important;
  }
  .tooltip-top::before {
    border-right: solid 6px transparent;
    border-left: solid 6px transparent;
    transform: translateX(-50%);
    position: absolute;
    z-index: -21;
    content: '';
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
    border-top: solid 6px rgba(0,0,0,0.9) !important;
  }
  .tooltip-bottom::before{
    border-right: solid 6px transparent;
    border-left: solid 6px transparent;
    transform: translateX(-50%);
    position: absolute;
    z-index: -21;
    content: '';
    bottom: 100%;
    left: 50%;
    height: 0;
    width: 0;
    border-bottom: solid 6px rgba(0,0,0,0.9) !important;
  }
  .tooltip-bottom-right::before{
    border-right: solid 6px transparent;
    border-left: solid 6px transparent;
    transform: translateX(140%);
    position: absolute;
    z-index: -21;
    content: '';
    bottom: 100%;
    left: 70%;
    height: 0;
    width: 0;
    border-bottom: solid 6px rgba(0,0,0,0.9) !important;
  }
  .tooltip-right::before{
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
    border-left: solid 6px transparent;
    border-right: solid 6px rgba(0,0,0,0.9) !important;
  }
  .tooltip-left::before{
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the left of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
    border-right: solid 6px transparent;
    border-left: solid 6px rgba(0,0,0,0.9) !important;
  }
  // @import "assets/scss/base.scss";
  // html,body{
  //   font-size: 13px !important;
  // }
  .fullloadingscreen{
    position: absolute;
    transition: all 0.5s ease;
    z-index: 25000;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
    & .text-center{
      text-align: center;
    }
    justify-content: center;
    align-items: center;    
  }

  .fade-in-image {
    animation: fadeIn 0.5s ease;
    -webkit-animation: fadeIn 0.5s ease;
    -moz-animation: fadeIn 0.5s ease;
    -o-animation: fadeIn 0.5s ease;
    -ms-animation: fadeIn 0.5s ease;
  }
  .fade-out-image {
    animation: fadeOut 1s ease;
    -webkit-animation: fadeOut 1s ease;
    -moz-animation: fadeOut 1s ease;
    -o-animation: fadeOut 1s ease;
    -ms-animation: fadeOut 1s ease;
    visibility: hidden;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @-moz-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @-webkit-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @-o-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @-ms-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes loadingbg-2-animation{
    0% {bottom: -10vh;left: -10vw;transform: rotate(20deg);}
    40% {bottom: -2vh;left: -10vw;transform: rotate(23deg);}
    70% {bottom: -5vh;left: -12vw;transform: rotate(18deg);}
    100% {bottom: -10vh;left: -10vw;transform: rotate(20deg);}
  }
  .loadingbg-2{
    animation: loadingbg-2-animation 15s infinite ease, fadeIn 2s ease;
    background-color: rgba(252, 99, 107, 0.5);
    height: 60vh;
    width: 20vw;
    bottom: -10vh;
    border-radius: 25px;
    left: -10vw;
    transform: rotate(20deg);
    position: fixed;
  }
  @keyframes loadingbg-3-animation{
    0% {transform: rotate(45deg);}
    40% {transform: rotate(40deg);}
    70% {transform: rotate(39deg);}
    100% {transform: rotate(45deg);}
  }
  .loadingbg-3{
    animation: loadingbg-3-animation 22s infinite ease, fadeIn 2s ease;
    background-color: rgba(106, 103, 206, 0.5);
    height: 50vh;
    width: 20vw;
    bottom: -30vh;
    border-radius: 25px;
    right: 10vw;
    position: fixed;
    transform: rotate(45deg);
  }
  @keyframes loadingbg-1-animation{
    0% {top: -45vh;}
    40% {top: -42vh;}
    70% {top: -48vh;}
    100% {top: -45vh;}
  }
  .loadingbg-1{
    animation: loadingbg-1-animation 22s infinite ease, fadeIn 2s ease;
    background-color: rgba(46, 182, 125, 0.5);
    height: 100vh;
    width: 60vw;
    top: -45vh;
    border-radius: 25px;
    right: -40vw;
    position: fixed;
    transform: rotate(-40deg);
  }
  @keyframes loadingbg-4-animation{
    0% {top: 15vh;transform: rotate(-40deg);}
    40% {top: 19vh;transform: rotate(-75deg);}
    70% {top: 17vh;transform: rotate(-69deg);}
    100% {top: 15vh;transform: rotate(-40deg);}
  }
  .loadingbg-4{
    animation: loadingbg-4-animation 22s infinite ease, fadeIn 2s ease;
    background-color: rgba(66, 133, 244, 0.5);
    height: 10vh;
    width: 10vh;
    top: 25vh;
    border-radius: 15px;
    left: 20vw;
    position: fixed;
    transform: rotate(-40deg);
  }

  .loading-hidden{
    transition: visibility 0s 2s, opacity 2s linear
  }

  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .FC-Table th{
    background-color: #667085 !important;
    color:white !important;
  }
  .v-table-color th{
    background-color: #e7f0f5 !important;
    color: rgba(0,0,0,0.9) !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background: white;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #E5E5E5;
    color:#E5E5E5 ;
  }
</style>