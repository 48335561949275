import Vue from 'vue';
import LBDateRange from './DateRange';

import LBDate from './Date';
import LBTime from './Time';
import LBString from './String';
import LBTextarea from './Textarea';
import LBNumber from './Number';
import LBList from './List';
import LBYear from './Year';
import LBMonth from './Month';
import LBFile from './File';
import LBDropdown from './Dropdown';
import TagInputs from './TagInputs.vue';

import FileNEw from "./FileNew"
import MultiSelectMonths from './MultiSelectMonths.vue';

Vue.component('lb-daterange', LBDateRange);
Vue.component('lb-textarea', LBTextarea);
Vue.component('lb-date', LBDate);
Vue.component('lb-time', LBTime);
Vue.component('lb-string', LBString);
Vue.component('lb-string', LBString);
Vue.component('lb-number', LBNumber);
Vue.component('lb-list', LBList);
Vue.component('lb-year', LBYear);
Vue.component('lb-month', LBMonth);
Vue.component('lb-file', LBFile);
Vue.component('lb-dropdown', LBDropdown);
Vue.component("lb-tagsInput",TagInputs);
Vue.component("lb-fileuploadNew", FileNEw);
Vue.component("lb-selectmultiplemonths", MultiSelectMonths)
