<template>
  <div>
    <div @click.stop="dialog = true">
      <v-text-field
        ref="element"
        prepend-inner-icon="mdi-calendar-range"
        dense
        outlined
        :label="label || 'Select Months'"
        clearable
        :error-messages="error"
        @keypress="dialog = true"
        @click:clear.stop="clearSelection"
        :hide-details="hidedetails"
        :disabled="disabled"
      ></v-text-field>
    </div>
    <v-dialog max-width="290px" v-model="dialog" transition="dialog-transition">
      <v-card>
        <!-- <v-card-text class="pa-0">
            <div v-if="selectedMonths.length" class="selected-months pa-0">
                <h3>Selected Months:</h3>
                <ul>
                <li v-for="(month, index) in selectedMonths" :key="index" class="month-item">
                    {{ month }}
                    <v-btn icon @click="removeMonth(month)">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </li>
                </ul>
            </div>
            <br>
        </v-card-text> -->
        <v-card-text class="pa-0">
          <v-date-picker
            elevation="0"
            v-model="tempDate"
            color="primary"
            :min="min"
            :max="max"
            type="month"
            @input="toggleMonthSelection"
            :readonly="readonly"
            multiple
          >

            <v-spacer></v-spacer>
            <v-btn text small @click="dialog = false">Close</v-btn>
            <v-btn
              small
              color="primary"
              @click="applySelection"
            >Ok</v-btn> 
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectedMonths: [],  // Store multiple months
      tempDate: ['2024-01'],        // Temporary date for date picker selection
    };
  },
  props: {
    error: [String, Array],
    label: {
        type:String,
        default:'Select Month(s)',

    },
    value: {
        type:Array,
        default: () => [],
    },
    min: {
        type:String,
        default:''
    },
    max: {
        type:String,
        default:''
    },
    hidedetails: {
        type:Boolean,
        default:false
    },
    disabled: {
        type:Boolean,
        default:false
    },
    readonly: {
        type:Boolean,
        default:false
    },
  },
  methods: {
    setValue() {
      this.selectedMonths = Array.isArray(this.value) ? [...this.value] : [];
    },
    toggleMonthSelection(month) {
        const monthString = month.slice(0, this.tempDate.length+1); // Format as 'YYYY-MM'
        if (this.selectedMonths.includes(monthString)) {
            // Remove the month if it’s already selected
            this.selectedMonths = this.selectedMonths.filter(
            (m) => m !== monthString
            );
        } else {
            // Add the month if it’s not selected
            this.selectedMonths= monthString;

        }
    },
    applySelection() {
      this.dialog = false;
      this.updateValue();
    },
    updateValue() {
      this.$emit("input", [...this.selectedMonths]);
      this.$emit("change", [...this.selectedMonths]);
    },
    clearSelection() {
      this.selectedMonths = [];
      this.updateValue();
    },
    removeMonth(month) {
      this.selectedMonths = this.selectedMonths.filter((m) => m !== month);
    },
  },
  computed: {
    formattedRangevalue() {
      return this.selectedMonths
        .map((month) => this.$nova.formatDate(month, "MMM YYYY"))
        .join(", ");
    },
  },
  watch: {
    value() {
      this.setValue();
    },
    dialog(){
        if(this.dialog === true){
            this.tempDate = this.value
            this.setValue()
        }
    }
  },
  created() {
    this.setValue();
  },
};
</script>

<style scoped>
.selected-months {
  margin-top: 20px;
}
.selected-months ul {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  padding: 0;
  margin: 0;
  list-style-type: none; /* Remove default list styling */
}
.month-item {
  display: flex;
  align-items: center;
  margin: 4px 8px; /* Spacing around items */
  padding: 4px 8px;
  background-color: #e0f7fa; /* Background color for items */
  border-radius: 4px;
  font-size: 0.9em;
}
</style>
