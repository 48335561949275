import Vue from 'vue';
import './Inputs';
// import PageHeader from './common/PageHeader';
import Divider from './common/Divider';
import Dialog from './common/DialogBox';
import ActivityLog from './common/ActivityLog';
import Conversation from './common/Conversation';
import HelpMenu from './common/helpMenu';
import DataTable from './common/DataTable';
import Drawer from './common/Drawer.vue';
import DragDropFile from './Inputs/DragDropfile.vue';
import DailogBoxNew from './common/DailogBoxNew.vue';
import ActivityLogNew from './common/ActivityLogNew.vue';
import DataTableNew from './common/DataTableNew.vue';
import ActivityLogWithIcon from './common/ActivityLogWithIcon.vue';
import VueCountryCode from "vue-country-code-select";
import ProfileStack from './common/ProfileStack.vue';
import TextBox from './Inputs/TextBox.vue';
import DataTableAction from './common/DataTableAction.vue';
import Datatable_pagination from './common/Datatable_pagination.vue'
import Filter from './common/Filter.vue'
import Comments from './common/Comments.vue';
import ActivitysidemenuNew from './common/ActivitysidemenuNew.vue';

// Vue.component('lb-pageheader', PageHeader);
Vue.component('vue-country-code',VueCountryCode)
Vue.component('lb-divider', Divider);
Vue.component('lb-dialog', Dialog);
Vue.component('lb-activitylog', ActivityLog);
Vue.component('lb-conversation', Conversation);
Vue.component('lb-helpmenu', HelpMenu);
Vue.component('lb-datatable', DataTable);
Vue.component('lb-datatablenew', DataTableNew);
Vue.component('lb-drawer',Drawer)
Vue.component('lb-fileDrop',DragDropFile)
Vue.component('lb-profilestack',ProfileStack)
Vue.component('lb-textBox',TextBox)
Vue.component('lb-datatableaction',DataTableAction)
Vue.component('lb-dailogboxnew', DailogBoxNew)
Vue.component('lb-activitylognew', ActivityLogNew);
Vue.component('lb-activitylogwithicon', ActivityLogWithIcon);
Vue.component('lb-datatable_pagination', Datatable_pagination);
Vue.component('lb-filter', Filter);
Vue.component('lb-comment', Comments);
Vue.component('lb-activitysidemenuNew',ActivitysidemenuNew)

