<template>
  <div v-if="width !== ''" class="" style="background: white;">
    <v-combobox v-if="customvalue"
      ref="element"
      style="background: white;"
      outlined
      dense
      :style="`width:${width}`"
      :label="label ?? 'Dropdown'"
      :clearable="clearable"
      :error-messages="error"
      v-model="content"
      @click:clear="content = ''"
      @change="updateValue()"
      :items="items"
      :chips="chips"
      :deletable-chips="chips"
      :hide-details="hidedetails"
      :disabled="disabled"
      :item-text="itemtext"
      :item-value="itemvalue"
      :readonly="readonly"
      :multiple="multiple"
      :prepend-inner-icon="prependinnericon"
      :append-icon="appendicon"
      :prefix="prefix"
      :suffix="suffix"
    ></v-combobox>
    <v-autocomplete
      ref="element"
      outlined
      dense
      :style="`width:${width}`"
      :label="label ?? 'Dropdown'"
      :clearable="clearable"
      :error-messages="error"
      v-model="content"
      @click:clear="content = ''"
      @change="updateValue()"
      :items="items"
      :chips="chips"
      :deletable-chips="chips"
      :hide-details="hidedetails"
      :disabled="disabled"
      :item-text="itemtext"
      :item-value="itemvalue"
      :readonly="readonly"
      :multiple="multiple"
      :prepend-inner-icon="prependinnericon"
      :append-icon="appendicon"
      :prefix="prefix"
      :suffix="suffix"
      :placeholder="placeholder"
    ></v-autocomplete>
  </div>
  <v-combobox
    ref="element"
    outlined
    dense
    style="background: white;"
    v-else-if="customvalue"
    :label="label ?? 'Dropdown'"
    :clearable="clearable"
    :error-messages="error"
    v-model="content"
    @click:clear="content = ''"
    @change="updateValue()"
    :items="items"
    :chips="chips"
    :deletable-chips="chips"
    :hide-details="hidedetails"
    :disabled="disabled"
    :item-text="itemtext"
    :item-value="itemvalue"
    :readonly="readonly"
    :multiple="multiple"
    :prepend-inner-icon="prependinnericon"
    :append-icon="appendicon"
    :prefix="prefix"
    :suffix="suffix"
  ></v-combobox>
  <v-autocomplete
    ref="element"
    outlined
    dense
    v-else
    :label="label ?? 'Dropdown'"
    :clearable="clearable"
    :error-messages="error"
    v-model="content"
    @click:clear="content = ''"
    @change="updateValue()"
    :items="items"
    :chips="chips"
    :deletable-chips="chips"
    :hide-details="hidedetails"
    :disabled="disabled"
    :item-text="itemtext"
    :item-value="itemvalue"
    :readonly="readonly"
    :multiple="multiple"
    :prepend-inner-icon="prependinnericon"
    :append-icon="appendicon"
    :prefix="prefix"
    :suffix="suffix"
    :placeholder="placeholder"
  ></v-autocomplete>
</template>

<script>
export default {
  activated() {
    this.setValue();
    this.updateValue();
  },
  created() {
    this.setValue();
    this.updateValue();
  },
  data: () => ({
    content: "",
    tempContent: "",
  }),
  props: {
    error: {
      type: [String, Array],
    },
    label: {
      type: String,
    },
    value: {
      type: [String, Array, Number],
      default: function () {
        return [];
      },
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    width: {
      type: String,
      default: "",
    },
    itemtext: {
      type: String,
      default: "name",
    },
    itemvalue: {
      type: String,
      default: "value",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    customvalue: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hidedetails: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    prependinnericon: {
      type: String,
      default: "",
    },
    appendicon: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder:{
      type:String,
      default:''
    }
    // required: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    setValue: function () {
      this.content = this.value;
    },
    updateValue: function () {
      this.$emit("input", this.content);
      this.$emit("change", this.content);
    },
    setFocus: function () {
      this.$nextTick(() => {
        this.$refs.element.$el.querySelector("input").focus();
      });
    },
  },
  computed: {},
  watch: {
    value: function () {
      this.$nextTick(() => {
        this.setValue();
      });
    },
    items: function () {
      let hasitem = false;
      if (this.items.length > 0) {
        if (
          typeof this.items[0] === "object" &&
          !(this.items[0] instanceof Array)
        ) {
          for (const i of this.items) {
            if ((i.value || "") === this.content) hasitem = true;
          }
        } else if (this.items.indexOf(this.content) > -1) hasitem = true;
      }
      if (!hasitem) this.content = "";
      if (this.items.length === 1 && (this.content || "").toString() === "") {
        if (this.items[0] instanceof Array)
          this.content = (this.items[0] || this.content || "").toString();
        else if (typeof this.items[0] === "object")
          this.content = (this.items[0].value || "").toString();
        this.updateValue();
      }
    },
  },
};
</script>