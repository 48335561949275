import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: 0,
    navigation: true,
    headeraction: true,
    sbsuccess: false,
    sbsuccessmsg: "",
    sberror: false,
    sberrormsg: "",
    sbinfo: false,
    sbinfomsg: "",
    sbwarning: false,
    sbwarningmsg: "",
    sidebarFlag: false,
    noauth: false,
    login: false,
    mininavbar: false,
    user: {},
    permission: {},
    isadmin: false,
    subscriptions: {},
    clientlist: [],
    containerheight: '0',
    client: "",
    refreshclient: false,
    menu: [],
    newmenu: [],
    custommenu: [],
    menulist: {
      // home: [
      //   { product: true, title: 'UCP Home', bgcolor: 'primary' },
      //   {
      //     title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/home/dashboard',
      //   },
      //   {
      //     title: 'Profile Info', icon: 'mdi mdi-card-account-details-outline', href: '/home/profile',
      //   },
      //   // {
      //   //   title: 'Payment & Subscription', icon: 'mdi mdi-credit-card-outline', href: '/home/subscription',
      //   // },
      // ],
      // admin: [
      //   { product: true, title: 'Admin', bgcolor: 'primary' },
      //   { header: true, title: 'Main Navigation' },
      //   {
      //     title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/admin/dashboard',
      //   },
      //   // {
      //   //   title: 'Analysis', icon: 'mdi mdi-graphql', href: '/admin/monthly',
      //   // },
      //   { header: true, title: 'Manage' },
      //   {
      //     title: 'Clients', icon: 'mdi mdi-account-tie', href: '/admin/client',
      //   },
      //   {
      //     title: 'Users', icon: 'mdi mdi-account-tie', href: '/admin/user',
      //   },
      //   // {
      //   //   title: 'Engagements', icon: 'mdi mdi-account-tie', href: '/admin/engagement',
      //   // },
      // ],
      // ia: [
      //   { product: true, title: 'Internal Audit', bgcolor: 'crisk' },
      //   { button: true, title: 'Add Assignment', href: '/ia/assignment/add' },
      //   // { header: true, title: 'Main Navigation' },
      //   {
      //     title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/ia/dashboard',
      //   },
      //   // { header: true, title: 'Manage' },
      //   // {
      //   //   title: 'Clients', icon: 'mdi mdi-account-tie', href: '/admin/client',
      //   // },
      //   // {
      //   //   title: 'Users', icon: 'mdi mdi-account-tie', href: '/admin/user',
      //   // },
      //   {
      //     title: 'Engagement Setup', icon: 'mdi mdi-account-tie', href: '/ia/engagement/view',
      //   },
      // ],
      // pbc: [
      //   { product: true, title: 'Information Workflow', bgcolor: 'crisk' },
      //   { button: true, title: 'Create Project', href: '/pbc/project#add' },
      //   // {
      //   //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
      //   // },
      //   {
      //     title: 'All Projects', icon: 'mdi mdi-format-list-group', href: '/pbc/project',
      //   },
      // ],
      // tds: [
      //   { product: true, title: 'Withholding Tax', bgcolor: 'ccompliance' },
      //   // { button: true, title: 'Create Analysis', href: '/tds/ar#add' },
      //   // {
      //   //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
      //   // },
      //   {
      //     title: 'AR Analysis', icon: 'mdi mdi-format-list-group', href: '/tds/ar',
      //   },
      // ],
      // earnout: [
      //   { product: true, title: 'Earnouts', bgcolor: 'ctransaction' },
      //   { button: true, title: 'Create Project', href: '/earnout/project#add' },
      //   // {
      //   //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
      //   // },
      //   {
      //     title: 'Projects', icon: 'mdi mdi-format-list-group', href: '/earnout/project',
      //   },
      // ],
      // tds: [
      //   { product: true, title: 'TDS', bgcolor: 'ccompliance' },
      //   { header: true, title: 'Main Navigation' },
      //   {
      //     title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/tds/dashboard',
      //   },
      //   {
      //     title: 'Account Receivables', icon: 'mdi mdi-calendar-month-outline', href: '/tds/ar',
      //   },
      //   {
      //     title: 'Account Payable', icon: 'mdi mdi-calendar-month-outline', href: '/tds/ap',
      //   },
      //   {
      //     title: 'Monthly', icon: 'mdi mdi-calendar-month-outline', href: '/tds/monthly',
      //   },
      //   { header: true, title: 'Manage' },
      //   {
      //     title: 'Vendor Master', icon: 'mdi mdi-text-account', href: '/tds/mastervendor',
      //   },
      // ]
    },
    downloadLoading: false,
    downloadHighlight: false,
    download: [],
    downloaderror: "",
    progressLoading:false,
    snackMultValue:[],
    sidedraweropen:false,
    projectName:"",
    LocationHeader:'',
  },

  mutations: {
    navigation(state, d) {
      state.navigation = d;
    },
    headeraction(state, d) {
      state.headeraction = d;
    },
    toogleNavBar(state, d) {
      state.mininavbar = d;
    },
    addLoading(state) {
      state.loading++;
    },
    removeLoading(state) {
      state.loading--;
    },
    setMenu(state, d) {
      state.menu = d;
    },
    setNewMenu(state, d) {
      state.newmenu = d;
    },
    setCustomMenu(state, d) {
      state.custommenu = d;
    },
    setClientList(state, d) {
      state.clientlist = d;
    },
    setAdmin(state, d) {
      state.isadmin = d;
    },
    sbSuccess(state, b) {
      snackBar(state, {type: "success", text: b, colors:"success"});
      // state.sbsuccess = false;
      // state.sbsuccess = true;
      // state.sbsuccessmsg = b;
    },
    sbError(state, b) {
      snackBar(state, {type: "error", text: b, colors:"error"});
      // state.sberror = false;
      // state.sberror = true;
      // state.sberrormsg = b;
    },
    sbWarning(state, b) {
      snackBar(state, {type: "warning", text: b, colors:"warning"});
      // state.sbwarning = false;
      // state.sbwarning = true;
      // state.sbwarningmsg = b;
    },
    sbInfo(state, b) {
      snackBar(state, {type: "info", text: b, colors:"info"});
      // state.sbinfo = false;
      // state.sbinfo = true;
      // state.sbinfomsg = b;
    },
    changeNoAuth(state, d) {
      state.noauth = d;
    },
    changeLogin(state, b) {
      state.login = b
    },
    setSubscription(state, b) {
      state.subscriptions = b || {};
    },
    setUser(state, d) {
      for (const key in d) {
      state.user[key] = d[key];
      }
    },
    setPermission(state, d) {
      state.permission = d
    },
    setClient(state, d) {
      state.client = d
    },
    refreshClient(state, d = true) {
      state.refreshclient = d;
    },
    setHeight(state, d = {}) {
      state.containerheight = d;
    },
    progressSetLoad(state,d){
      state.progressLoading = d
    },
    setdrawerOpen(state,d){
      state.sidedraweropen=d
    },
    setProjectName(state,d){
      state.projectName= d
    },
    setLocationHeader(state,d){
      
      state.LocationHeader=d
    }
    // refreshDownloads(state, d){
    //   if(d.axios){
    //     // console.log(d.highlight);
    //     state.downloadLoading = true;
    //     d.axios.post('/conserve/analysis/gettopdownloadlist').then(dt => {
    //       if(dt.data.status == "success"){
    //         if(dt.data.data.length > 0){
    //           state.downloaderror = "";
    //           state.download = dt.data.data;
    //           // console.log(state.download);
    //           if(d.highlight != "no"){
    //             state.downloadHighlight = true;
    //             setTimeout(function(){state.downloadHighlight = false;},3000);
    //           }
    //         }else{
    //           state.download = [];
    //           state.downloaderror = "No downloads";
    //         }
    //       }else {
    //         state.download = [];
    //         state.downloaderror = "Unable to fetch list";
    //       }
    //     }).catch(err => {
    //       console.log(err);
    //       state.download = [];
    //       state.downloaderror = "Error while fetching list";
    //     }).finally(() => {
    //       state.downloadLoading = false;
    //       console.log(state.download);
    //       // this.$store.commit("endDownload", vid);
    //     });
    //   }
    // }
    // startDownload(state, x){
    //   if(state.download.indexOf(x.toString()) === -1) state.download.push(x.toString());
    //   // console.log(this.download);
    // },
    // endDownload(state, x){
    //   let index = state.download.indexOf(x.toString());
    //   if(index > -1) state.download.splice(index, 1);
    //   // console.log(this.download);
    // }
  },
  getters: {
    // formatDate: function(state){
    //   return function(dt){
    //     if(dt && moment(dt).isValid()) return moment(dt).format("D MMM YYYY").toString();
    //     else return dt
    //   }
    // },
    // formatDateISO: function(state){
    //   return function(dt){
    //     if(dt && moment(dt).isValid()) return moment(dt).format("YYYY-MM-DD").toString();
    //     else return dt
    //   }
    // } 
  },
  actions: {
  },
  modules: {
  }
})

function snackBar(state={}, data){
  let value = {
    showing: true,
    text: data.text,
    type: data.type,
    time: 3000,
    colors:data.colors
  }
  state.snackMultValue = (state.snackMultValue || []).filter((x) => x.showing !== false);
  state.snackMultValue.push(value);
}
