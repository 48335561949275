<template>
    <v-container class="d-flex align-start justify-start pa-0 px-2">
        <div class="pb-3">
        <p class="text-capitalize font-weight-bold pa-0 ma-0">{{heading}}</p>

        <v-menu
            v-model="value"
            :close-on-content-click="false"
            offset-y
            color="white"
            class="pa-0 ma-0"
            style="background-color: white;"
        >
        <template v-slot:activator="{ on, attrs }">
            <span
            v-bind="attrs"
            v-on="on"
            class="pa-0 text-overline blue--text"
            >
            Edit
            </span>
        </template>
        <v-list class="overflow-hidden radius-small pa-0 pt-2 mb-0" style="min-width: 18vh;">
            <div class="font-weight-bold text-subtitle px-3 pb-1 d-flex justify-space-between">
            <p class="pa-0 ma-0">{{ heading }}</p>
            <v-icon>mdi-information-outline</v-icon>
            </div>
            <v-divider></v-divider>
            <div class="overflow-scroll px-2 userMenu">
            <v-list-item
                v-for="(item, index) in allUser"
                :key="index"
                class="pa-0"
                style="background-color: white;"
            >
                <v-list-item-content class="pa-0 ma-0">
                <v-checkbox
                    :ripple="false"
                    class="pa-0 ma-0"
                    @change="checkedData(item)"
                    hide-details
                    color="caccounting"
                    :input-value="ifUserSelected(item)"
                    :label="item.name"
                ></v-checkbox>
                </v-list-item-content>
            </v-list-item>
            </div>
            <v-divider></v-divider>
            <div class="d-flex justify-space-between">
                <v-spacer></v-spacer>
                <v-btn small color="primary" class="ma-1" @click="updateuser()" >Update</v-btn>
            </div>
        </v-list>
        </v-menu>

        </div>
        <v-spacer></v-spacer>
        <div class="d-flex all-width justify-start ml-8 pt-1">
                <div class="d-flex">
                    <div
                      v-for="(item, index) in items.slice(0, 3)"
                      :key="index"
                       class="avatars-group__item "
                    >
                      <v-tooltip top content-class="tooltip-top">
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            v-on="on"
                            size="27"
                            color="caccounting"
                            class="white--text border-on d-flex pa-0 ma-0 v-avatar"
                        >
                            {{ getusernamefrommainlist(item)[0] }}
                        </v-avatar>
                        </template>
                    <span> {{ getusernamefrommainlist(item) }}</span>
                      </v-tooltip>
                    </div>
                  </div>

            <v-tooltip v-if="items.length > 3 && items.length < 8"  top content-class="tooltip-top">
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar 
                        v-bind="attrs" 
                        v-on="on"
                        size="25"
                        color="lightpurple"
                        style="margin-left:-6px !important"
                        class="caccounting--text d-flex align-center justify-center pa-0 ma-0"
                    >
                    <v-icon color="caccounting">mdi-plus</v-icon>
                    </v-avatar>
                </template>
                <div class="" v-for="(name,index) in items.slice(3)" :key=index>
                    <span>{{getusernamefrommainlist(name)}}</span>
                </div>
            </v-tooltip>

            <v-menu
                v-else-if=" items.length >= 8" 
                v-model="dropdown"
                :close-on-content-click="false"
                offset-y
                color="white"
                class="pa-0 ma-0"
                style="background-color:white"
            >
            <template v-slot:activator="{ on, attrs }">
            <v-avatar
                size="25"
                color="lightpurple"
                class="caccounting--text d-flex align-center justify-center pa-0 ma-0"
                v-bind="attrs"
                v-on="on"
                style="margin-left:-6px !important"
            >
                <v-icon color="caccounting" >mdi-plus</v-icon>
            </v-avatar>
            </template>
            <v-list class="overflow-hidden radius-small pa-0 pt-2 mb-0 " style="min-width:18vh">
            <div class="font-weight-bold text-subtitle px-3 pb-1 d-flex justify-space-between">
                <p class="pa-0 ma-0 ">{{heading}}</p>
                <v-icon>mdi-information-outline</v-icon>
            </div>
            <v-divider></v-divider>
            <div class="overflow-scroll px-2 userMenu">
                <v-list-item
                    v-for="(item, index) in items.slice(3)"
                    :key="index"
                    style="background-color:white"
                    class="pa-0"
    
                >
                    <v-list-item-content  class="pa-0 ma-0">
                        <span> {{ getusernamefrommainlist(item) }}</span>
                    </v-list-item-content>
                </v-list-item>
            </div>
            </v-list>
            </v-menu>
        </div>
    </v-container>
</template>
<script>

export default {
    name: 'ProfileStack',
    data:  function() {
        return {
                dropdown :"",
                newdataa:[],
                presence: false,
                stackedLimit: 6,
            }
    },
    props: {
        items: {
            type: Array,
            default: function () {
                return [];
            },
        },
        heading:{
            type:String,
            default:"",
        },
        allUser: {
            type: Array,
            default: function () {
                return [];
            },
        },
        value: {
            type: [],
            default:  function () {
                return [];
            },
        },
        updateFunction:{
            type: Function,
        }
        
    },
    computed: {
        avatarsSorted() {
            return [...this.items].sort((a, b) => a.alt.localeCompare(b.alt));
        },
        avatarsStackedLimited() {
            return this.avatarsSorted.slice(0, this.stackedLimit);
        },
    },
    methods: {
        updateuser(){   
            this.updateFunction()         
        },
        checkedData(item){
            if(this.items.includes(item._id)){
                let elementIndex = this.items.indexOf(item._id)
                this.items.splice(elementIndex,elementIndex+1)
            }
            else
            {
                this.items.push(item._id)
            }
        },
        userNames(){
            let usernameslist = this.items.map((item)=>{
                return item._id
            })            
            return usernameslist.splice(3,usernameslist.length)
        },
        ifUserSelected(value) {
            const selectedusers = this.allUser.filter(user => this.items.includes(user._id));
            return selectedusers.includes(value);
        },
        getusername(id){
            
            const name = (this.items || []).find(
                (loc) => loc._id === id
            );
            return name ? name.name : "Unknown";
        },
        getusernamefrommainlist(id) {
            if (typeof id === 'object' && id !== null) {
                id = id._id;  
            }
            const name = (this.allUser || []).find(
                (loc) => loc._id === id
            );
            return name ? name.name : "Unknown";
        }

    },
    watch:{
    }
}

</script>

<style scoped>
.userMenu{
    max-height: 23vh !important;
    background-color: white;
    overflow: scroll;
}
  
  .v-tooltip .v-tooltip__content {
    white-space: nowrap;
  }
</style> 
