<template>
  <div>
  <v-textarea  counter ref="element" outlined dense type="text" :label="label || 'Text'" clearable 
    :error-messages="error" v-model="content" @click:clear="content = ''" @input="updateValue()" :hide-details="hidedetails" :disabled="disabled"
    :append-icon="appendiconinner" @click:append="emitevent('click:append')" auto-grow 
  ></v-textarea>
  </div>
</template>

<script>
  export default {
    activated() {
      this.setValue();
      this.updateValue();
    },
    created() {
      this.setValue();
      this.updateValue();
    },
    data: () => ({
      content: "",
      tempContent: "",
    }),
    props: {
      error: {
        type: [String, Array],
      },
      label: {
        type: String,
      },
      value: {
        type: String
      },
      appendiconinner: {
        type: String
      },
      hidedetails: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      height:{
        type: String,
        default: ""
      },
      placeholder:{
        type:String,
        default:""
      }
    },
    methods: {
      setValue: function(){
        this.content = this.value;
      },
      updateValue: function () {
        if (this.required) {
          if (this.content && this.content.trim() !== '') {
            this.$emit('input', this.content.trim());
            this.$emit('change', this.content.trim());
          }else{
            this.$emit('input', null);
            this.$emit('change', null);
          }
        } else {
          this.$emit('input', this.content);
          this.$emit('change', this.content);
        }
      },
      setFocus: function(){
        this.$nextTick(() => {
          this.$refs.element.$el.querySelector('input').focus();
        })
      },
      emitevent: function(d){
        console.log("called");
        this.$emit(d, '');
      }
    },
    computed:{
    },
    watch: {
      value: function(){
        this.setValue();
      }
    }
  }
</script>
<style scoped>
.v_input .v-input__control .v-input__slot .v-text-field__slot .textarea{
  max-height: 100px !important;
}
</style>