import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#193441',
                fbcPrimary:'#667085',
                // primary2: '#d787ff',
                // primary3: '#eeccff',
                secondary: '#3E606F',
                // secondary2: '#7d7d7d',
                // secondary3: '#d9d9d9',
                cream: '#eef1e6',
                accent: '#91AA9D',
                // accent2: '#fcfb7c',
                // accent3: '#ffffc2',
                warning: '#ff9c07',
                info: '#00bcd4',
                success: '#4caf50',
                error: '#b22222',
                indigo: '#3F51B5',
                teal: '#027A48',
                amberamber: '#FFC107',
                lightgrey : '#475467',
                background: '#F8F8F8',
                lightred:'#FDF4F4',
                ccompliance: '#FC636B',
                crisk: '#2EB67D',
                caccounting: '#6B67CE',
                ctransaction: '#4285F4',
                lightpurple: '#F4F4FB',
                purpleborder:'#EBEBF7',
                purple:'#6B67CE',
                gray:'#808080',
                darkgray:'#444444',
                lightblue:'#F3F6FE',
                blue:'#4285F4',
                lightyellow:'#FAF7F2',
                yellow:'#C69908',
                lightgreen:'#F3F8F5',
                green:'#37AE78',
                lightgray:'#F7F7F7',
                btnColor: "#667085",
                lightorange:'#FFFAEB',
                orange: '#B54708',
                lightCaccounting: "#F9F5FF",
               
            }
        },
        options: {
            customProperties: true
        }
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});
