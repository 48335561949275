<template>
  <v-text-field :style="`max-width:${width} !important ; border-radius:${radius} !important`" ref="element" outlined dense class="my-0" :type="type" :label="label ?? 'Text'" :clearable="isClearable"
    :error-messages="error" v-model="content" @click:clear="content = ''" @input="updateValue()" @keydown.enter="$emit('enter')" :hide-details="hidedetails" :disabled="disabled"
    :append-icon="appendiconinner" @click:append="emitevent('click:append')" :rounded="rounded" :prepend-inner-icon="prependiconinner" :placeholder="placeholder"
  ></v-text-field>
</template>

<script>
  export default {
    activated() {
      this.setValue();
      this.updateValue();
    },
    created() {
      this.setValue();
      this.updateValue();
    },
    data: () => ({
      content: "",
      tempContent: ""
    }),
    props: {
      error: {
        type: [String, Array],
      },
      label: {
        type: String,
      },
      value: {
        type: String
      },
      appendiconinner: {
        type: String
      },
      prependiconinner: {
        type: String
      },
      hidedetails: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "text"
      },
      width: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      },
      rounded: {
        type: Boolean,
        default: false
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      radius:{
        type:String,
        default:""
      },
      placeholder:{
        type:String,
        default:""
      },
      isClearable:{
        type: Boolean,
        default: true
      }
    },
    methods: {
      setValue: function(){
        this.content = this.value;
      },
      updateValue: function () {
        if(this.isRequired){
          if(this.content && this.content.trim() !== ''){
            this.$emit('input', this.content.trim());
            this.$emit('change', this.content.trim());
          }
        }else{
          this.$emit('input', this.content);
          this.$emit('change', this.content);
        }
      },
      setFocus: function(){
        this.$nextTick(() => {
          this.$refs.element.$el.querySelector('input').focus();
        })
      },
      emitevent: function(d){
        console.log("called");
        this.$emit(d, '');
      }
    },
    computed:{
    },
    watch: {
      value: function(){
        this.setValue();
      }
    }
  }
</script>